<template>
    <div class="body">
        <!-- banner区域 Start -->
        <div class="banner">
            <m-top-bar></m-top-bar>
        </div>
        <!-- banner区域 End -->

        <!-- switchBar区域 Start -->
        <div class="switchBar">
            <div class="switchBox">青海油田无线传输监控项目</div>
        </div>
        <div class="line"></div>
        <!-- switchBar区域 End -->

        <!-- container区域 Start -->
        <div class="container">
            <div class="containerBox">
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;青海油田在方圆150 平方公里的油田开采区城内，在各单井、计转站和联合站等生产现场，
                设置无线监控点;在油田总控案，建立系统中心基站。选用新诺同络的电信级无线网桥产品，
                配合高增盐天线，采用点对点及点对多点的无线连接方式，建立油田总控室与各监控点间的无线传输主链路，
                实现生产现场图像情息、生产数据、设备运转数据、原油储备和运输信息等的实时动态传输，
                为采油生产的指挥调度提供准确及时的第一手资料。同时，在很多无人职守的油田生产区域，
                原油及其生产设施的安全保卫工作，也是相关部门工作的重中之重。生产现场的无线远程视频监控网络，
                为生产现场的安全保卫工作提供了强有力的支持，保障了油田保卫部门对不法犯罪活动的快速打击。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;各监控点由视频采集处理系统和信号传输系统两大部分组成，其中视频采集处理系统包括了摄像机、云台及其控制器、网络视频服务器;
                信号传输系统由无线网桥、天情系统等构成。系统采用先进的数字处理技术，
                将监控摄像机拍摄的阳像信息处理为基于TCPAP 的数据包，通过无线网塔传输到远端监控中心。<br>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;监控中心基站由电信级无线网桥与天馈系统组成。监控阳像的实时显示、存档、童询等功能，
                全部由计算机系统或数字硬盘录像机完成。通过授权可以达成系统资源的共享，
                实现通过LAN 或INTERNET 的远程监控，远端险控中心也可拉制前端监控摄像机，
                根据操作人员的需要，拍摄不同方位、不同尺度的生产现场视频图像
            </div>
        </div>
        <!-- container区域 End -->

        <!-- baseBar区域 Start -->
        <m-base-bar></m-base-bar>
        <!-- baseBar区域 End -->
    </div>
</template>

<script>
import mTopBar from '@/components/mTopBar.vue'
import mBaseBar from '@/components/mBaseBar.vue'

export default {
    name: 'Support',
    components: {
        mTopBar,
        mBaseBar,
    }
}
</script>

<style scoped>
.body {
    background-color: #f7f7f7 !important;
}

/* banner区域 */
.banner {
    width: 100%;
    min-height: 2050px;
    background: url(../../../assets/mobile/img/support/banner.jpg) center no-repeat;
    background-size: 230%;
}

/* switchBar区域 */
.switchBar {
    padding-top: 50px;
    width: 100%;
    height: 300px;
    text-align: center;
    background-color: #ffffff;
}

.switchBar .switchBox {
    margin: 0 auto;
    text-align: center;
    width: 1200px;
    height: 200px;
    font-size: 82px;
    line-height: 200px;
    color: #ffffff;
    border-radius: 30px;
    background-color: #c3a35d;
}

.line {
    margin: 0 auto;
    width: 90%;
    border-bottom: 1px solid #c3a35d;
}

/* container区域 */
.container {
    padding: 50px;
    width: 100%;
    min-height: 500px;
}

.container .containerBox {
    padding: 100px;
    width: 100%;
    min-height: 300px;
    font-size: 70px;
    text-align: justify;
    background-color: #ffffff;
}
</style>